var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"modal":"","title":"SET FILE","size":"xmd","modal-class":"modal-primary","title-tag":"h3","no-close-on-backdrop":true},on:{"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success","disabled":Object.keys(_vm.selectedFolder).length == 0 ? true : false},on:{"click":_vm.copyPoBoxToClientFolder}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" DONE")],1)]},proxy:true}]),model:{value:(_vm.modalSetFilePoBox),callback:function ($$v) {_vm.modalSetFilePoBox=$$v},expression:"modalSetFilePoBox"}},[_c('ValidationObserver',{ref:"form"},[_c('b-row',[_c('b-col',{staticClass:"mt-2 mb-4",attrs:{"cols":"6"}},[_c('b-input-group',{attrs:{"prepend":"ACCOUNT"}},[_c('div',{staticClass:"\n                form-control\n                h-auto\n                bg-transparent\n                border-secondary\n                sms-container\n              "},[_vm._v(" "+_vm._s(_vm.userDataNotVerified.client_account)+" ")])])],1),_c('b-col',{staticClass:"mt-2 mb-4",attrs:{"cols":"6"}},[_c('b-input-group',{attrs:{"prepend":"CLIENT"}},[_c('div',{staticClass:"\n                form-control\n                h-auto\n                bg-transparent\n                border-secondary\n                sms-container\n              "},[_vm._v(" "+_vm._s(_vm.userDataNotVerified.full_name)+" ")])])],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"4"}},[_c('span',[_vm._v("Select a client:")])]),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"8"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors[0] ? false : null}},[_c('v-select',{attrs:{"options":_vm.optionsClient,"label":"full_name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var full_name = ref.full_name;
var client_account = ref.client_account;
return [_c('span',[_vm._v(_vm._s((full_name + " / (" + client_account + ")")))])]}},{key:"selected-option",fn:function(ref){
var full_name = ref.full_name;
var client_account = ref.client_account;
return [(Object.keys(_vm.userDataNotVerified).length > 0)?_c('span',[_vm._v(_vm._s((full_name + " / (" + client_account + ") ")))]):_vm._e()]}}],null,true),model:{value:(_vm.userDataNotVerified),callback:function ($$v) {_vm.userDataNotVerified=$$v},expression:"userDataNotVerified"}})],1)]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"4"}},[_c('span',[_vm._v("Pick a date:")])]),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"8"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.datePoBox),callback:function ($$v) {_vm.datePoBox=$$v},expression:"datePoBox"}})]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"4"}},[_c('span',[_vm._v("Pick a document:")])]),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"8"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"state":errors[0] ? false : null,"options":_vm.optionsDoc,"value-field":"value","text-field":"name"},model:{value:(_vm.selectedDoc),callback:function ($$v) {_vm.selectedDoc=$$v},expression:"selectedDoc"}})]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"4"}},[_c('span')]),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"8"}},[(
              _vm.selectedDoc != 'court-letters' &&
              _vm.selectedDoc != 'others' &&
              _vm.selectedDoc != null
            )?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors[0] ? false : null}},[(
                  _vm.selectedDoc != 'court-letters' &&
                  _vm.selectedDoc != 'others' &&
                  _vm.selectedDoc != null
                )?_c('v-select',{attrs:{"options":_vm.docItem},on:{"change":_vm.changeFileName},model:{value:(_vm.selectedSubDoc),callback:function ($$v) {_vm.selectedSubDoc=$$v},expression:"selectedSubDoc"}}):_vm._e()],1)]}}],null,false,2908676198)}):_vm._e()],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"4"}},[_c('span',[_vm._v("File name:")])]),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"8"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.fileName),callback:function ($$v) {_vm.fileName=$$v},expression:"fileName"}})]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('span',[_vm._v("Select the folder where you will copy this File PO BOX")])]),_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('b-button-group',_vm._l((_vm.arrayFoldersByClient),function(folder){return _c('b-button',{key:folder.id,staticClass:"mb-2",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.pickFolder(folder)}}},[_c('feather-icon',{attrs:{"size":"20","icon":"FolderIcon"}}),_vm._v(" "+_vm._s(folder.file_name))],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }