<template>
  <div>
    <b-modal
      modal
      title="SET FILE"
      v-model="modalSetFilePoBox"
      size="xmd"
      modal-class="modal-primary"
      @hidden="closeModal"
      title-tag="h3"
      :no-close-on-backdrop="true"
    >
      <ValidationObserver ref="form">
        <b-row>
          <b-col cols="6" class="mt-2 mb-4">
            <b-input-group prepend="ACCOUNT">
              <div
                class="
                  form-control
                  h-auto
                  bg-transparent
                  border-secondary
                  sms-container
                "
              >
                {{ userDataNotVerified.client_account }}
              </div>
            </b-input-group>
          </b-col>
          <b-col cols="6" class="mt-2 mb-4">
            <b-input-group prepend="CLIENT">
              <div
                class="
                  form-control
                  h-auto
                  bg-transparent
                  border-secondary
                  sms-container
                "
              >
                {{ userDataNotVerified.full_name }}
              </div>
            </b-input-group>
          </b-col>
          <b-col cols="4" class="mb-2">
            <span>Select a client:</span>
          </b-col>
          <b-col cols="8" class="mb-2">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-form-group :state="errors[0] ? false : null">
                <v-select
                  v-model="userDataNotVerified"
                  :options="optionsClient"
                  label="full_name"
                >
                  <template #option="{ full_name, client_account }">
                    <span>{{ `${full_name} / (${client_account})` }}</span>
                  </template>
                  <template #selected-option="{ full_name, client_account }">
                    <span v-if="Object.keys(userDataNotVerified).length > 0">{{
                      `${full_name} / (${client_account}) `
                    }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col cols="4" class="mb-2">
            <span>Pick a date:</span>
          </b-col>
          <b-col cols="8" class="mb-2">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-form-datepicker
                v-model="datePoBox"
                :state="errors[0] ? false : null"
              ></b-form-datepicker>
            </ValidationProvider>
          </b-col>
          <b-col cols="4" class="mb-2">
            <span>Pick a document:</span>
          </b-col>
          <b-col cols="8" class="mb-2">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-form-radio-group
                :state="errors[0] ? false : null"
                v-model="selectedDoc"
                :options="optionsDoc"
                value-field="value"
                text-field="name"
              ></b-form-radio-group>
            </ValidationProvider>
          </b-col>
          <b-col cols="4" class="mb-2">
            <span></span>
          </b-col>
          <b-col cols="8" class="mb-2">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              v-if="
                selectedDoc != 'court-letters' &&
                selectedDoc != 'others' &&
                selectedDoc != null
              "
            >
              <b-form-group :state="errors[0] ? false : null">
                <v-select
                  v-if="
                    selectedDoc != 'court-letters' &&
                    selectedDoc != 'others' &&
                    selectedDoc != null
                  "
                  v-model="selectedSubDoc"
                  :options="docItem"
                  @change="changeFileName"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col cols="4" class="mb-2">
            <span>File name:</span>
          </b-col>
          <b-col cols="8" class="mb-2">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-form-input
                v-model="fileName"
                :state="errors[0] ? false : null"
              ></b-form-input>
            </ValidationProvider>
          </b-col>
          <b-col cols="12" class="mb-2">
            <span>Select the folder where you will copy this File PO BOX</span>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-button-group>
              <b-button
                class="mb-2"
                variant="warning"
                v-for="folder in arrayFoldersByClient"
                :key="folder.id"
                @click="pickFolder(folder)"
                ><feather-icon size="20" icon="FolderIcon" />
                {{ folder.file_name }}</b-button
              >
            </b-button-group>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #modal-footer>
        <b-button
          variant="success"
          :disabled="Object.keys(selectedFolder).length == 0 ? true : false"
          @click="copyPoBoxToClientFolder"
          ><feather-icon icon="SaveIcon" /> DONE</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import FilePOBoxService from "@/views/commons/components/file-po-box/services/file-po-box.service";
import { mapGetters } from "vuex";

export default {
  components: {
    vSelect,
  },
  props: {
    userPoBox: {
      type: Object,
    },
  },
  data() {
    return {
      buttonSelected: null,
      selectedDoc: null,
      selectedSubDoc: null,
      selectedFolder: {},
      optionsDoc: [
        { value: "collections", name: "Collections" },
        { value: "dispute-answers", name: "Dispute answers" },
        { value: "personal-information", name: "Personal information" },
        { value: "court-letters", name: "Court Letters" },
        { value: "others", name: "Others" },
      ],
      userDataNotVerified: {},
      arrayFoldersByClient: [],
      optionsClient: [],
      modalSetFilePoBox: false,
      datePoBox: new Date().toISOString().split("T")[0],
      fileName: null,
    };
  },
  async created() {
    this.modalSetFilePoBox = true;
    await this.getClientsNotInVerifiedPoboxes();
  },
  methods: {
    async copyPoBoxToClientFolder() {
      try {
        let success = await this.$refs.form.validate();
        if (!success) return;
        this.addPreloader();
        const params = {
          client_account_id: this.userDataNotVerified.client_account_id,
          client_id: this.userDataNotVerified.client_id,
          destination_folder: this.selectedFolder.route,
          id_folder: this.selectedFolder.id,
          id_user: this.currentUser.user_id,
          name_file: this.fileName,
          name_folder: this.selectedFolder.file_name,
          pobox_id: this.userPoBox.id,
          size_file: this.userPoBox.zip_code,
          url: this.userPoBox.url,
        };
        const data = await FilePOBoxService.copyPoBoxToClientFolder(params);
        if (data.status == 200) {
          this.$emit("reload");
          this.showSuccessSwal();
          this.closeModal();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    pickFolder(folder) {
      this.selectedFolder = folder;
    },
    async getFoldersByClient() {
      try {
        let data = await FilePOBoxService.getFoldersByClient(
          this.userDataNotVerified.client_account_id
        );
        if (data.status == 200) {
          this.arrayFoldersByClient = data.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getClientsNotInVerifiedPoboxes() {
      try {
        const params = {
          pobox_id: this.userPoBox.id,
          pobox_zip_code: this.userPoBox.zip_code,
        };
        const data = await FilePOBoxService.getClientsNotInVerifiedPoboxes(
          params
        );
        if (data.status == 200) {
          this.optionsClient = data.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    docItem() {
      const arrayDocs = [
        [
          "C-ERC",
          "C-PORTFOLIO",
          "C-JEFFERSON_CAPITAL",
          "C-RUSH_&_CURTIES",
          "C-OTHERS",
        ],
        ["DA-TU", "DA-EQ", "DA-EX"],
        ["PI-UB", "PI-ID"],
        ["COURT_LETTERS"],
        ["OTHERS"],
      ];
      let item = arrayDocs.find((element, i) =>
        this.selectedDoc === this.optionsDoc[i].value ? element : ""
      ); //this.selectedDoc == element.value ? arrayDocs[i] : ""
      //selectedDoc==optionsDoc[0].value?arrayDocs[0] : selectedDoc==optionsDoc[0].value?arrayDocs[0]
      //console.log(item);
      return item;
    },
    changeFileName() {
      let dateFile = new Date().toISOString().split("T")[0];
      return this.selectedSubDoc != null
        ? (this.fileName = `${this.selectedSubDoc}_${dateFile}`)
        : "";
    },
  },
  watch: {
    selectedDoc(newVal) {
      let dateFile = new Date().toISOString().split("T")[0];
      if (newVal == "court-letters") {
        this.fileName = `COURT_LETTERS_${dateFile}`;
      } else if (newVal == "others") {
        this.fileName = `OTHERS_${dateFile}`;
      } else {
        this.fileName = null;
        this.selectedSubDoc = null;
      }
    },
    async userDataNotVerified() {
      await this.getFoldersByClient();
    },
  },
};
</script>

<style></style>
