export default [
  {
    key: "zip_code",
    label: "Zip code",
  },
  {
    key: "url",
    label: "File",
  },
  {
    key: "updated_at",
    label: "Uploaded By",
  },
  {
    key: "action",
    label: "action",
  },
];
